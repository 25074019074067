<template>
  <div>
    <div class="container">
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-button type="primary" @click="addbanner()">新增</el-button>
        </div>
        <div style="float:right;">

          <div class="seach_box">
            <el-input
              placeholder="请输入名称"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
            </el-input>
          </div>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="序号" align="center" prop="id"></el-table-column>

          <el-table-column label="名称" prop="imgName" align="center"></el-table-column>
          <el-table-column label="banner" align="center">
            <template v-slot="{ row }">
              <div>
                <el-image style="width: 100px;height: 50px;" :preview-src-list="[row.imgUrl]" :src="row.imgUrl" alt="" />
              </div>
            </template>
          </el-table-column>

          <el-table-column label="链接" prop="companyFullName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.link }}</span>
            </template>
          </el-table-column>
          <el-table-column label="智参中心" prop="content" align="center">
            <template v-slot="{ row }">
            <span  style="display:flex;justify-content: center;">
                  <span style="margin-right: 10px;" v-for="(o, index) in row.wisdomGinsengNames" :key="index">
                  <span  class="office">{{ o }}</span>
                </span>
                </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="createTime" align="center">
            <template v-slot="{ row }">
              <span class="edit" @click="editclick(row)">编辑</span>
              <span v-if="row.isDelete!=1" class="frozen" @click="carouselDelete(row)">冻结</span>
              <span v-if="row.isDelete==1" class="frozen" @click="carouselActivate(row)">激活</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="新增" :visible.sync="DialogVisible" width="527px" class="account_dialog">
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="form">
        <el-form-item label="名称:" prop="imgName">
          <el-input v-model="adminUser.imgName" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="banner:" prop="imgUrl">
          <img-big-upload
            :imageShow.sync="adminUser.imgUrl"
            :addTitleShow="false"
            @removeImg="removeImgDian"
            @uploadOneImgShow="uploadOneImgShow"
          />
        </el-form-item>
        <span style="font-size: 14px; color: #999999; position: relative; left: 100px; top: -10px"
          >建议上传图片整体尺寸为442*226 px，大小不超过8MB</span
        >
        <el-form-item label="链接:" prop="link">
          <div class="txtree">
            <el-input
              v-model="adminUser.link"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入对应的链接ＩＤ，例如：/pages/activitydetails/activitydetails?id=87"
              show-word-limit
            />
          </div>
        </el-form-item>
        <el-form-item label="海报:" prop="">
          <img-big-upload
            :imageShow.sync="adminUser.poster"
            :addTitleShow="false"
            @removeImg="removeImgDians"
            @uploadOneImgShow="uploadOneImgShows"
          />
        </el-form-item>
        <el-form-item label="智参中心:" prop="wisdomGinsengIds">
          <el-checkbox-group v-model="adminUser.wisdomGinsengIds">
            <el-checkbox v-for="(el, index) in listrole" :key="index" :label="el.id">{{
              el.zcName
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm()">确 定</el-button>
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { carouselPage, addinsert, updatebanner,getCarouselDetail ,carouselDelete,carouselActivate} from '@/api/system'
import { getWisdomGinsengList } from '@/api/channel'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  orderByParam: null,
  queryConditions:'',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'Diagnosisrecord',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      DialogVisible: false,
      adminUser: {
        id: null,
        imgName: null,
        imgUrl: '',
        link: null,
        poster:'',
        carouselFlag: 1,
        wisdomGinsengIds: []
      },
      listrole: [],
      adminrules: {
        imgName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        imgUrl: [{ required: true, message: '请上传banner', trigger: 'blur' }],
        link: [{ required: true, message: '请输入链接', trigger: 'blur' }],
        wisdomGinsengIds: [{ required: true, message: '请勾选角色', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await carouselPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    //查询智参中心
    async listAll() {
      const res = await getWisdomGinsengList()
      if (res.data.resultCode == 200) {
        this.listrole = res.data.data


      }
    },
    //新增banner
    addbanner() {
      this.DialogVisible = true
      this.listAll()
     this.adminUser={
        id: null,
        imgName: null,
        imgUrl: '',
        link: null,
        poster:'',
        carouselFlag: 1,
        wisdomGinsengIds: []
      }
    },
    //编辑
    editclick(row){

      this.getCarouselDetail(row.id)
      this.DialogVisible = true
      this.listAll()
    },
    //banner详情
   async getCarouselDetail(id){
    const query={
      id:id
    }

    const res=await getCarouselDetail(query)
    if (res.data.resultCode == 200) {
      this.adminUser=res.data.data

    }
    },
    carouselDelete(row){

      const query = {
        id:row.id
      }
      this.$alert('您是否确认冻结？')
        .then(function () {
          return carouselDelete(query)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活
    carouselActivate(row){
      const query = {
        id:row.id
      }
      this.$alert('您是否确认激活？')
        .then(function () {
          return carouselActivate(query)
        })
        .then(() => {
          this.search()
          this.$message.success('激活成功')
        })
        .catch(() => {})
    },
    //保存banner
    submitForm() {
      console.log(this.adminUser)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            addinsert(this.adminUser).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.DialogVisible = false
                this.search()
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            updatebanner(this.adminUser).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.DialogVisible = false
                this.search()
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.adminUser.imgUrl = ''
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.adminUser.imgUrl = value
    },
    removeImgDians(val) {
      this.adminUser.poster = ''
    },
    // 图片上传路径长地址  logo
    uploadOneImgShows(value) {
      this.adminUser.poster = value
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 80vh;
  overflow: scroll;
}
 .seach_box {
    display: flex;
    margin: 15px 0;


    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
 .office {
  display: inline-block;
  background: #edf4ff;
              border: 1px solid #559eff;
              color: #4e93fb;
              border-radius: 2px;
              font-size: 12px;
              padding:0px 3px;
            }
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
